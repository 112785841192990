(function () {
    'use strict';

    angular.module('informaApp')
        .component('barChartTabsInfo', {
            templateUrl: 'components/chart-view-profile/bar-chart-tabs-info/template.html',
            controller: [BarChartTabsInfoController],
            bindings: {
                source: '<'
            }
        });

    function BarChartTabsInfoController() {
        var vm = this;

        this.$onInit = function () {
            vm.tabsData = mapTabsData();
        };

        function mapTabsData() {
            return vm.source.map(function(x, i, array) {
                return _.merge({}, x, {tabId: 'tab-' + (i + 1)});
            });
        }
    }
})();